/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_tree .
 *= require_self
 */

 body{
    margin: 0%;
    padding: 0%;
  }

 a{
    text-decoration: none !important;
 }

 .transparency{
   background-color: rgba(0, 0, 0, 0.5);
 }

 .cssbuttons-io-button {
   background: #A370F0;
   color: white;
   font-family: inherit;
   padding: 0.35em;
   padding-left: 1.2em;
   font-size: 17px;
   font-weight: 500;
   border-radius: 0.9em;
   border: none;
   letter-spacing: 0.05em;
   display: flex;
   align-items: center;
   box-shadow: inset 0 0 1.6em -0.6em #714da6;
   overflow: hidden;
   position: relative;
   height: 2.8em;
   padding-right: 3.3em;
 }
 
 .cssbuttons-io-button .icon {
   background: white;
   margin-left: 1em;
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 2.2em;
   width: 2.2em;
   border-radius: 0.7em;
   box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;
   right: 0.3em;
   transition: all 0.3s;
 }
 
 .cssbuttons-io-button:hover .icon {
   width: calc(100% - 0.6em);
 }
 
 .cssbuttons-io-button .icon svg {
   width: 1.1em;
   transition: transform 0.3s;
   color: #7b52b9;
 }
 
 .cssbuttons-io-button:hover .icon svg {
   transform: translateX(0.1em);
 }
 
 .cssbuttons-io-button:active .icon {
   transform: scale(0.95);
 }
 

 .love-heart:before,#switch {
   display: none;
  }
  
  .love-heart, .love-heart::after {
   border-color: #DEE1ED;
   border: 1px solid;
   border-top-left-radius: 100px;
   border-top-right-radius: 100px;
   width: 10px;
   height: 8px;
   border-bottom: 0
  }
  
  .round {
   position: absolute;
   z-index: 1;
   width: 8px;
   height: 8px;
   background: #fff;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 4px 0px;
   border-radius: 100%;
   left: 0px;
   bottom: -1px;
   transition: all .5s ease;
   animation: check-animation2 .5s forwards;
  }
  
  input:checked + label .round {
   transform: translate(0px, 0px);
   animation: check-animation .5s forwards;
   background-color: #C1D9BF
  }
  
  @keyframes check-animation {
   0% {
    transform: translate(0px, 0px);
   }
  
   50% {
    transform: translate(0px, 7px);
   }
  
   100% {
    transform: translate(7px, 7px);
   }
  }
  
  @keyframes check-animation2 {
   0% {
    transform: translate(7px, 7px);
   }
  
   50% {
    transform: translate(0px, 7px);
   }
  
   100% {
    transform: translate(0px, 0px);
   }
  }


  label {
   /* Presentation */
   font-size: 48px
 }
 
 /* Required Styling */
 
 label input[type="checkbox"] {
   display: none;
 }
 
 .custom-checkbox {
   margin-left: 2em;
   position: relative;
   cursor: pointer;
 }
 
 .custom-checkbox .glyphicon {
   color: gold;
   position: absolute;
   top: 0.4em;
   left: -1.25em;
   font-size: 0.75em;
 }
 
 .custom-checkbox .glyphicon-star-empty {
   color: gray;
 }
 
 .custom-checkbox .glyphicon-star {
   opacity: 0;
   transition: opacity 0.2s ease-in-out;
 }
 
 .custom-checkbox:hover .glyphicon-star{
   opacity: 0.5;
 }
 
 .custom-checkbox input[type="checkbox"]:checked ~ .glyphicon-star {
   opacity: 1;
 }


 /* Make the tag position relative to the figure */
.figure.tag {
   position: relative;
 }
 /* set the base styles all tags should use */
 .figure.tag::before {
   position: absolute;
   top: 10%;
   display: block;
   color: white;
   padding: 0.5rem 1rem;
   font-weight: bold;
 }
 /* Specific variations for different tags */
 .figure.tag-under-construction::before {
   content: "Under Construction";
   background: orange;
 }

 .figure.tag-ready-to-move::before {
   content: "Ready To Move";
   background: rgb(149, 119, 63);
 }
 
 .figure.tag-upcoming::before {
   content: "Upcoming";
   background: rgb(217, 206, 184);
 }
 
 .figure.tag-completed::before {
   content: "Completed";
   background: rgb(26, 201, 49);
 }

 .figure.tag-just-lauched::before {
   content: "Just Launched";
   background: rgb(120, 138, 204);
 }

  .figure.tag-on-sale::before {
    content: "On Sale";
    background: rgb(120, 138, 204);
  }
 
 
 .figure.tag-out::before {
   content: "Out of Stock";
   background: #e2e2e2;
   border: #ccc;
   color: #444;
   top: 42%;
   right: 0;
   left: 0;
   width: 30%;
   margin: 0 auto;
   text-align: center;
 }
 

 .contact-form-wrapper {
  padding: 0px;
}

.contact-form {
  padding: 10px 10px;
  background-color: #ffffff;
  border-radius: 12px;
  max-width: 400px;
}

.contact-form textarea {
  resize: none;
}

.contact-form .form-input,
.form-text-area {
  background-color: #f0f4f5;
  height: 50px;
  padding-left: 16px;
}

.contact-form .form-text-area {
  background-color: #f0f4f5;
  height: auto;
  padding-left: 16px;
}

.contact-form .form-control::placeholder {
  color: #aeb4b9;
  font-weight: 500;
  opacity: 1;
}

.contact-form .form-control:-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .form-control::-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .form-control:focus {
  border-color: #f33fb0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #f33fb0;
}

.contact-form .title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.contact-form .description {
  color: #aeb4b9;
  font-size: 14px;
  text-align: center;
}

.contact-form .submit-button-wrapper {
  text-align: center;
}

.contact-form .submit-button-wrapper input {
  border: none;
  border-radius: 4px;
  background-color: #f23292;
  color: white;
  text-transform: uppercase;
  padding: 10px 60px;
  font-weight: 500;
  letter-spacing: 2px;
}

.contact-form .submit-button-wrapper input:hover {
  background-color: #d30069;
}

.amenity-width{
  width: 100%;
}


.search_group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 190px;
}

.search_input {
  height: 20px;
  line-height: 28px;
  padding: 0 1rem;
  width: 70%;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: #D9E8D8;
  color: #0d0c22;
  box-shadow: 0 0 5px #C1D9BF, 0 0 0 10px #f5f5f5eb;
  transition: .3s ease;
}

.search_input::placeholder {
  color: #777;
}

.search_icon {
  position: absolute;
  left: 1rem;
  fill: #777;
  width: 1rem;
  height: 1rem;
}








.whats-app {
  position: fixed;
  width: 60px;
  bottom: 40px;
  right: 0px;
  background-color: #25d366;
  color: #FFF;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.facebook {
  position: fixed;
  width: 60px;
  bottom: 88px;
  right: 0px;
  background-color: #3610de;
  color: #ffffff;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #cab7b7;
  z-index: 100;
}

.youtube {
  position: fixed;
  width: 60px;
  bottom: 136px;
  right: 0px;
  background-color: #c4302b;
  color: #ffffff;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #cab7b7;
  z-index: 100;
}

label {
  /* Presentation */
  font-size: x-large !important;
}

.text-justify{
  text-align: justify;
}

/* 
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.border-0 {
  border: 0 !important;
}
.position-relative {
  position: relative !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
}
.card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: .25rem;
} */

.member-profile {
  top: -50px;
  left: 0;
}
/* .text-center {
  text-align: center !important;
}
.w-100 {
  width: 100% !important;
}
.position-absolute {
  position: absolute !important;
} */

.member-profile img {
  width: 100px;
  height: 100px;
}
/* .rounded-circle {
  border-radius: 50% !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
} */


.project-imgs {
  width:  100%;
  height: 100%;
  object-fit: cover;
}